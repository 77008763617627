var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crew-member-edit"},[_c('v-container',{staticClass:"container-md"},[_c('v-card',[_c('v-card-title',[_c('user-profile-picture',{attrs:{"photoId":_vm.getStaffProfilePicture,"userId":_vm.$route.params.userId},on:{"update-profile":_vm.getStaffMember}}),_c('div',{staticClass:"ml-4"},[_vm._v("Staff Member - "+_vm._s(_vm.model.fullname))]),_c('v-spacer')],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onUpdateStaffMember($event)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('base-input',{attrs:{"topLabel":"Firstname","placeholder":"Firstname","rules":[_vm.requiredRule],"readonly":_vm.agencyAdminRole},model:{value:(_vm.model.firstname),callback:function ($$v) {_vm.$set(_vm.model, "firstname", $$v)},expression:"model.firstname"}})],1),_c('v-col',[_c('base-input',{attrs:{"topLabel":"Lastname","placeholder":"Lastname","rules":[_vm.requiredRule],"readonly":_vm.agencyAdminRole},model:{value:(_vm.model.lastname),callback:function ($$v) {_vm.$set(_vm.model, "lastname", $$v)},expression:"model.lastname"}})],1)],1),_c('v-row',[_c('v-col',[_c('base-input',{attrs:{"topLabel":"Email Address","placeholder":"Email Address","readonly":_vm.agencyAdminRole},model:{value:(_vm.model.emailAddress),callback:function ($$v) {_vm.$set(_vm.model, "emailAddress", $$v)},expression:"model.emailAddress"}})],1)],1),_c('v-row',[_c('v-col',[_c('base-input',{attrs:{"topLabel":"Position","placeholder":"Position","readonly":_vm.agencyAdminRole},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}})],1)],1),_c('v-row',[_c('v-col',[_c('base-date-picker',{attrs:{"topLabel":"Date of Birth","placeholder":"Date of Birth","readonly":_vm.agencyAdminRole,"rules":[
                  _vm.requiredRule,
                  _vm.dateBefore(
                    _vm.model.dateOfBirth,
                    _vm.maxDate,
                    'Date of birth',
                    'today'
                  )
                ]},model:{value:(_vm.model.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.model, "dateOfBirth", $$v)},expression:"model.dateOfBirth"}})],1),_c('v-col',[_c('base-date-picker',{attrs:{"topLabel":"Employment Start Date","placeholder":"Employment Start Date","readonly":_vm.agencyAdminRole,"rules":[
                  _vm.requiredRule,
                  _vm.dateBefore(
                    _vm.model.employmentStartDate,
                    _vm.maxDate,
                    'Employment Start Date',
                    'today'
                  )
                ]},model:{value:(_vm.model.employmentStartDate),callback:function ($$v) {_vm.$set(_vm.model, "employmentStartDate", $$v)},expression:"model.employmentStartDate"}})],1)],1),_c('v-row',[_c('v-col',[_c('base-input',{attrs:{"topLabel":"Next of Kin","placeholder":"Next of Kin","readonly":_vm.agencyAdminRole},model:{value:(_vm.model.nextOfKin),callback:function ($$v) {_vm.$set(_vm.model, "nextOfKin", $$v)},expression:"model.nextOfKin"}})],1),_c('v-col',[_c('base-input',{attrs:{"topLabel":"Next of Kin Number","placeholder":"Next of Kin Number","readonly":_vm.agencyAdminRole},model:{value:(_vm.model.nextOfKinTelephoneNumber),callback:function ($$v) {_vm.$set(_vm.model, "nextOfKinTelephoneNumber", $$v)},expression:"model.nextOfKinTelephoneNumber"}})],1)],1)],1),_c('v-card-actions',[_c('base-secondary-btn',{on:{"click":_vm.onBack}},[_vm._v(" Cancel ")]),(!_vm.agencyAdminRole)?_c('base-primary-btn',{attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Save")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }