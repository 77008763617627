

































































































































import { Component, Prop, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import AgencyAutocomplete from "@/components/common/agency/AgencyAutocomplete.vue";
import NationalityAutocomplete from "@/components/common/country/NationalityAutocomplete.vue";
import JobRoleAutocomplete from "@/components/common/job-role/JobRoleAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import moment from "moment";
import momentUtility from "@/utility/momentUtility";
import { UserModel } from "@/api/generated";
import UserModule from "@/store/userModule";
import UserProfilePicture from "@/components/user/UserProfilePicture.vue";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    AgencyAutocomplete,
    NationalityAutocomplete,
    JobRoleAutocomplete,
    UserProfilePicture
  }
})
export default class CrewMemberEdit extends Validation {
  @Ref() private readonly form!: VForm;
  @Prop(String) private userId!: string;

  private maxDate: moment.Moment = moment();
  private loading = false;
  private deleteLoading = false;
  private updateProfilePictureDialog = false;
  private model: UserModel = {
    firstname: "",
    lastname: "",
    role: "",
    enabled: false,
    isStaff: false
  };

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  get getStaffProfilePicture() {
    return this.model.profilePictureId;
  }

  private async created() {
    await this.getStaffMember();
  }

  private async getStaffMember() {
    const response = await Api.UserService.apiUserUserIdGet(this.userId);

    this.model = response.data;

    if (this.model.dateOfBirth) {
      this.model.dateOfBirth = momentUtility.pickerFormat(
        this.model.dateOfBirth
      );
    }
    if (this.model.employmentStartDate) {
      this.model.employmentStartDate = momentUtility.pickerFormat(
        this.model.employmentStartDate
      );
    }
  }

  private async onUpdateStaffMember() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;

      await Api.UserService.apiUserUserIdPut(this.userId, this.model);
      await this.getStaffMember();

      snackbarModule.setSnackbarMessage("Staff member saved");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to save staff member");
    } finally {
      this.loading = false;
    }
  }
}
