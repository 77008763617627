









































import { Component, Prop, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import ApiService from "@/services/apiService";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { apiClient } from "@/services/apiService";
import CrewMemberDocumentCreateModel from "@/models/crewMemberDocumentCreateModel";
import FormDataFactory from "@/utility/formDataFactory";
import { VesselEnquiryCreateModel } from "@/api/generated";
import VesselDocumentCreateModel from "@/models/vesselDocumentCreateModel";

const snackbarModule = getModule(SnackbarModule);
@Component
export default class UserProfilePicture extends Validation {
  @Ref() private readonly form!: VForm;
  @Prop(String) private photoId!: string;
  @Prop(String) private userId!: string;
  @Prop(Boolean) private canEdit!: boolean;
  @Prop(Boolean) private listItem!: boolean;
  private profilePictureDialog = false;
  private selectedProfilePicture: File | null = null;
  private modal = false;
  private loading = false;
  private model = {
    userId: "",
    document: null
  };

  get userPhoto(): string {
    return `${ApiService.getBaseURL()}/api/user/photo/${this.photoId}`;
  }

  private onUploadProfilePicture() {
    if (this.canEdit) {
      this.modal = true;
    }
  }

  private async uploadProfilePicture() {
    if (this.form.validate()) {
      if (!this.model.document) {
        snackbarModule.setSnackbarMessage("No file selected");
        return;
      }
      this.model.userId = this.userId;
      const formData = FormDataFactory.Create(this.model);

      try {
        this.loading = true;
        await apiClient.post("api/user/photo", formData);
        snackbarModule.setSnackbarMessage("Upload Success");
      } catch {
        snackbarModule.setSnackbarMessage("Failed to Upload");
      } finally {
        this.$emit("update-profile");
        this.loading = false;
        this.profilePictureDialog = false;
      }
    }
  }
}
